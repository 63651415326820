/**
 * Javascript file for enabling add to cart from a tile.
 *
 * Used on product tiles, flex tiles, and bundle tiles.
 */

import Glide from '@glidejs/glide';
import Tooltip from 'tooltip.js';

// ####### THIS CODE HAS BEEN CONVERTED TO VANILLA JS FROM JQUERY AND IS NOT IN USE.
// ####### IF IT WERE TO BE USED AGAIN, IT SHOULD BE TESTED PROPERLY
document.addEventListener('DOMContentLoaded', function () {
  if (SteelSeries && SteelSeries.hasOwnProperty('bindAddToCart')) {
    var gridPurchaseForms = Array.from(
      document.querySelectorAll('.prod-grid .js-purchase-form'),
    );
    SteelSeries.bindAddToCart(gridPurchaseForms);
  }

  document.querySelectorAll('.js-bundle-tile').forEach(function (item) {
    var bundleCTX = item;
    var bundleForm = bundleCTX.querySelector('.js-selection-form');
    var bundleFormContainer = bundleCTX.querySelector(
      '.bundle-selection-form-wrapper',
    );
    var showFormButton = bundleCTX.querySelector('.js-bundle-show-selection');
    var cancelFormButton = bundleCTX.querySelector('.js-bundle-hide-selection');

    showFormButton.addEventListener('click', function (e) {
      e.preventDefault();
      bundleFormContainer.classList.add('is-active');
      bundleFormContainer.setAttribute('aria-expanded', 'true');
      bundleForm.setAttribute('aria-hidden', 'false');
      bundleForm
        .querySelector('button, input, select')
        .setAttribute('tabindex', '');
      showFormButton.setAttribute('tabindex', '-1');
    });

    cancelFormButton.on('click', function (e) {
      e.preventDefault();
      bundleFormContainer.classList.remove('is-active');
      bundleFormContainer.setAttribute('aria-expanded', 'false');
      bundleForm.setAttribute('aria-hidden', 'true');
      bundleForm
        .querySelector('button, input, select')
        .setAttribute('tabindex', '-1');
      showFormButton.setAttribute('tabindex', '');
    });
  });
});
// #################################
// #################################

// Tooltips
function initTooltips(container) {
  Array.from(
    container.querySelectorAll('.glide__slides .js-product-list-tooltip'),
  ).forEach((el) => {
    const tooltipTitle = el.getAttribute('data-title');
    if (tooltipTitle) {
      new Tooltip(el, {
        title: tooltipTitle,
        placement: 'top', // or bottom, left, right, and variations
        offset: '0, 8px',
        delay: { show: 250 },
        container: document.body,
        boundariesElement: document.body,
        arrowSelector: '.tooltip__arrow',
        innerSelector: '.tooltip__inner',
        template:
          '<div class="tooltip" role="tooltip"><div class="tooltip__arrow"></div><div class="tooltip__inner"></div></div>',
      });
      el.removeAttribute('data-title');
    }
  });
}

// Related carousel

function initGlides(container) {
  Array.from(
    container.querySelectorAll('.catalog-list-product__related-items .glide'),
  ).forEach((el) => {
    const variants = Array.from(el.querySelectorAll('.glide__slide'));
    const variantControls = el.querySelector('.glide__arrows');
    const controlsRight = el.querySelector('.glide__arrow--right');
    const controlsLeft = el.querySelector('.glide__arrow--left');

    if (
      variantControls === null ||
      controlsRight === null ||
      controlsLeft === null
    ) {
      return;
    }

    if (variants.length <= 4) {
      variantControls.classList.add('hidden');
    } else {
      variantControls.classList.remove('hidden');
    }

    const variantCarousel = new Glide(el, {
      perView: 4,
      gap: 2,
      bound: true,
      rewind: false,
      dragThreshold: false,
      swipeThreshold: false,
    });

    // Hides leftmost arrow control at beginning and rightmost at end
    variantCarousel.on(['mount.after', 'run'], () => {
      if (variants.length > 4) {
        if (variantCarousel.index === 0) {
          controlsLeft.classList.add('hidden');
          controlsRight.classList.remove('hidden');
        } else if (variantCarousel.index === variants.length - 4) {
          controlsRight.classList.add('hidden');
          controlsLeft.classList.remove('hidden');
        } else {
          controlsRight.classList.remove('hidden');
          controlsLeft.classList.remove('hidden');
        }
      }
    });

    variantCarousel.mount();
  });
}

const flexGrids = Array.from(document.querySelectorAll('.js-prod-grid'));
flexGrids.forEach((container) => {
  initTooltips(container);
  initGlides(container);
});
